import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { graphql } from "gatsby";

import {
  Article,
  HeroService,
  ListServiceCategories,
  Page,
  Section,
  SectionSplit,
  useSectionStyles,
} from "components";
import { FormContact } from "containers";

const TemplateService = ({
  data: {
    service: {
      body,
      frontmatter: {
        description,
        form,
        header,
        image,
        page,
        seo,
        service,
        tags,
        title,
      },
    },
    serviceCategories,
  },
  location: { href, origin, pathname },
}) => {
  const styles = useSectionStyles();
  const hasServiceCategories =
    serviceCategories && serviceCategories.edges.length > 0;
  const isLarge = useMediaQuery(({ breakpoints }) => breakpoints.up("lg"));
  const serviceLowerCase = service.toLowerCase();
  const serviceTypes = tags && tags.map((tag) => ` ${tag.toLowerCase()}`);

  const seoOptions = {
    title: `${service} Services`,
    description,
    ...seo,
    openGraph: {
      title,
      url: `${href}`,
      ...seo?.openGraph,
      images: [
        {
          url: `${origin}/${image.childImageSharp.fluid.src}`,
          width: 1080,
          height: 720,
          alt: title,
        },
      ],
    },
  };

  const pageOptions = {
    header: {
      color: "default",
    },
    ...page,
  };

  const headerOptions = {
    heading: title,
    ...header,
  };

  const featureOptions = {
    bgcolor: "secondary.dark",
    gridProps: {
      className: "form right",
      spacing: 8,
    },
  };

  // TODO: add hero options to contact form container to avoid this messy config
  const formOptions = {
    buttonCopy: "Get a quote",
    className: "feature frame",
    context: `We provide professional ${serviceLowerCase} services of all types${
      serviceTypes && ` - ${serviceTypes}`
    } and much more. Fill out your name, email and a short description of what type of ${serviceLowerCase} services you'll need and we'll get back to you.`,
    headerProps: {
      className: "primary",
    },
    heading: `Professional ${serviceLowerCase} services in Henderson, Las Vegas, and other parts of Southern Nevada`,
    paperProps: {
      className: "clear",
    },
    referralProps: {
      category: service,
      page: pathname,
    },
    ...form,
  };

  const hiddenSectionOptions = {
    bgcolor: "primary.light",
    className: "hero",
  };

  // TODO: move all instances of renderArticle into a reusable component or hook
  const renderArticle = (pageType) => {
    switch (pageType) {
      case "media":
        return null;
      default:
        return (
          <Section>
            <Article content={body} />
          </Section>
        );
    }
  };

  return (
    <Page options={pageOptions} seo={seoOptions}>
      <HeroService {...headerOptions} />
      <SectionSplit
        {...featureOptions}
        left={
          hasServiceCategories && (
            <ListServiceCategories
              serviceCategories={serviceCategories.edges}
            />
          )
        }
        right={
          !formOptions.isHidden && isLarge && <FormContact {...formOptions} />
        }
      >
        {image && (
          <Img
            alt={image.name || title}
            className={classNames(styles.image, "background secondary")}
            fluid={image.childImageSharp.fluid}
          />
        )}
      </SectionSplit>
      {renderArticle(pageOptions.pageType)}
      <Hidden lgUp>
        <Section {...hiddenSectionOptions}>
          <FormContact {...formOptions} />
        </Section>
      </Hidden>
    </Page>
  );
};

TemplateService.propTypes = {
  data: PropTypes.shape({
    service: PropTypes.shape({
      body: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        description: PropTypes.string,
        form: PropTypes.shape({
          buttonCopy: PropTypes.string,
          context: PropTypes.string,
          heading: PropTypes.string,
          isHidden: PropTypes.bool,
        }),
        header: PropTypes.shape({
          heading: PropTypes.string,
        }),
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
        }),
        page: PropTypes.shape({
          header: PropTypes.shape({
            color: PropTypes.string,
          }),
          pageType: PropTypes.string,
        }),
        seo: PropTypes.shape({
          title: PropTypes.string,
        }),
        service: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
      }),
    }),
    serviceCategories: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              category: PropTypes.string,
              draft: PropTypes.bool,
              image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number,
                    base64: PropTypes.string,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
              }),
              order: PropTypes.number,
              title: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const serviceBySlugQuery = graphql`
  query ServiceBySlug($slug: String!) {
    service: mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        description
        form {
          buttonCopy
        }
        header {
          heading
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1280, maxHeight: 720, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        page {
          pageType
        }
        service
        tags
        title
      }
    }
    serviceCategories: allMdx(
      filter: {
        fields: { slug: { regex: $slug } }
        fileAbsolutePath: { regex: "/data/services/" }
        frontmatter: { category: { ne: null }, draft: { eq: false } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            draft
            image {
              childImageSharp {
                fluid(maxWidth: 80, maxHeight: 80, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            order
            title
          }
          id
        }
      }
    }
  }
`;

export default TemplateService;
